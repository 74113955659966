<template>

	<div class="cont payments sales" id="p27">
		<div class="c01">
			<div class="p-header">
				<div class="page-title">{{ program.name }}</div>
					
				<div class="cont-toggle">
					<div class="btn-toggle"
						:class="{ active: dateType == 'd'}"
						@click="setSearchDate('d')"
					>일간</div>
					<div class="btn-toggle"
						:class="{ active: dateType == 'w'}"
						@click="setSearchDate('w')"
					>주간</div>
					<div class="btn-toggle"
						:class="{ active: dateType == 'm'}"
						@click="setSearchDate('m')"
					>월간</div>
					<div class="btn-toggle"
						:class="{ active: dateType == 'c'}"
						@click="setSearchDate('c')"
					>선택</div>
				</div>
				
				<div class="cont-date">
					<div class="parent-datepicker">
						<input type="text" class="datepicker"
							placeholder="시작일"
							v-model="search.inquiryStartDate"
							@click="setSearchDate('s');"
							readonly
						>
						<v-date-picker
							v-if="datePicker.start.view"
							v-model="search.inquiryStartDate"
							no-title
							scrollable
							style="position: absolute; top: 70px; left: 660px; border: 1px solid #bbb;"
							@change="close"
						></v-date-picker>
					</div>
					
					<span>~</span>
					
					<div class="parent-datepicker">
						<input type="text" class="datepicker"
							placeholder="종료일"
							v-model="search.inquiryEndDate"
							@click="setSearchDate('e');"
							readonly
						>
						<v-date-picker
							v-if="datePicker.end.view"
							v-model="search.inquiryEndDate"
							no-title
							scrollable
							style="position: absolute; top: 70px; left: 880px; border: 1px solid #bbb;"
							@change="close"
						></v-date-picker>
					</div>
				</div>
				
				<div class="btn-excel"
					v-if="excel_items.length > 0"
				><vue-excel-xlsx
					:data="excel_items"
					:columns="columns"
					:filename="program.name"
					:sheetname="program.name"
				>엑셀 다운로드
				</vue-excel-xlsx></div>
				
				<div class="btn-excel"
					v-if="!excel_items.length"
				>
				엑셀 다운로드
				</div>
			</div>
			<div class="month-fee">
				<span class="p1">영업점 총 매출</span>
				<span class="amount">{{ summary.paymentTotal | makeComma }}원</span>
			</div>
		</div>
		
		<div class="c02">
			<div class="table type12">
				<div class="item-header">
					<div class="item">
						<div class="td">영업 담당자</div>
						<div class="td">연락처</div>
						<div class="td">결제 건수</div>
						<div class="td">결제금액</div>
						<div class="td">정산 수수료</div>
						<div class="td">정산 금액</div>
						<div class="td">상세</div>
					</div>
					<div class="item total">
						<div class="td">{{ summary.branchCount | makeComma }}명</div>
						<div class="td"></div>
						<div class="td num">{{ summary.paymentCount | makeComma }}건</div>
						<div class="td num">{{ summary.paymentTotal | makeComma }}원</div>
						<div class="td num">{{ summary.settlementTotalFee | makeComma }}원</div>
						<div class="td num">{{ summary.settlementTotalAmount | makeComma }}원</div>
						<div class="td"></div>
					</div>
				</div>
				<div class="item-list"
					v-for="(item, index) in items"
					:key="index"
				>
					<div class="item">
						<div class="td">{{ item.salesName }}</div>
						<div class="td">{{ item.contact | makeTell }}</div>
						<div class="td num">{{ item.paymentCount | makeComma }}건</div>
						<div class="td num">{{ item.paymentTotal | makeComma }}원</div>
						<div class="td num">{{ item.settlementTotalFee | makeComma }}원</div>
						<div class="td num">{{ item.settlementTotalAmount | makeComma }}원</div>
						<div class="td">
							<v-btn
								small
								:to="{ name: 'PaymentsSalesHistory', params: { userSeq: item.userSeq, orgSeq: item.orgSeq, start: search.inquiryStartDate, end: search.inquiryEndDate } }" class="success2">상세보기</v-btn>
						</div>
					</div>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>
				<div class="text-center mt-10">
					<Pagination
						:options="search"
					/>
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	import { date } from '@/resources/date/date'
	import { columns } from '@/resources/columns/columns'
	
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user']
		,components: { Pagination }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[2].list[1].name
					,items: {
					}
				}
				,items: [
				]
				,columns: columns.Sales.payment
				,summary: {
				}
				,search: {
					inquiryMonth: date.getToday()
					,inquiryStartDate: ''
					,inquiryEndDate: ''
				}
				,agentSeq: this.user.basic.agentSeq
				,orgSeq: this.user.basic.orgSeq
				,isActive: {
					prev: true
					,next: false
				}
				,excel_items: {
				}
				,dateType: 'm'
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				
				try{
					const result = await Axios({
						method: 'get'
						,url: '/branch/payment/history/'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
					
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getSummary: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/branch/payment/summary'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
						await this.getData()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
					
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getSummarySales: async function(userSeq, orgSeq){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/branch/payment/sales/' + userSeq + '/' + orgSeq + '/summary'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
					
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,goDetail: async function(userSeq, orgSeq){
				if(this.user.basic.level == 1){
					try{
						const result = await Axios({
							method: 'get'
							,url: '/branch/payment/sales/' + userSeq + '/' + orgSeq + '/history'
							,data: this.search
							,authorize: true
						})
						
						if(result.success){
							this.items = result.data.content.content
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
						
						this.getSummarySales(userSeq, orgSeq)
						
					}catch(E){
						this.$emit('setNotify', { type: 'error', message: E })
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: '하위 영업점 정보가 없습니다' })
				}
			}
			,prev: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0,6) >= this.search.inquiryMonth.substring(0, 6)){
					this.isActive.next = true
				}
				this.search.inquiryMonth = date.getPrevMonth(this.search.inquiryMonth).fullDate
				await this.getData()
			}
			,next: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0,6) <= this.search.inquiryMonth.substring(0, 6)){
					return false
				}
				this.search.inquiryMonth = date.getNextMonth(this.search.inquiryMonth).fullDate
				if(now.replaceAll('-', '').substring(0,6) <= this.search.inquiryMonth.substring(0, 6)){
					this.isActive.next = false
				}
				
				await this.getData()
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/branch/payment/history/download'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.excel_items = result.data.content.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,setSearchDate: async function(type){
			
				this.close()

				if(type == 'd'){
					this.search.inquiryStartDate = this.today
					this.search.inquiryEndDate = this.today
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 's'){
					this.search.inquiryStartDate = ''
					this.datePicker.start.view = true
					type = 'c'
				}else if(type == 'e'){
					this.search.inquiryEndDate = ''
					this.datePicker.end.view = true
					type = 'c'
				}else{
					this.search.inquiryStartDate = date.getSearchBaseDate()
					this.search.inquiryEndDate = date.getToday()
				}

				this.dateType = type

				if(this.search.inquiryStartDate && this.search.inquiryEndDate) {
					await this.getData()
				}
			}
			,close: function(){
				this.$set(this.datePicker.start, 'view', false)
				this.$set(this.datePicker.end, 'view', false)
			}
		}
		,created: function(){
			this.setSearchDate('m')
		}
		,watch: {
			search: {
				deep: true
				,handler: function(call){
					if(call.inquiryStartDate){
						this.search.inquiryStartDate = call.inquiryStartDate.replaceAll('-', '')
					}
					if(call.inquiryStartDate){
						this.search.inquiryEndDate = call.inquiryEndDate.replaceAll('-', '')
					}
					
					if(call.inquiryStartDate && call.inquiryStartDate){
						this.getSummary()
					}
				}
			}
		}
	}
</script>

<style>
	.payments.sales .td:nth-child(1) { width: 10% !important;}
	.payments.sales .td:nth-child(2) { width: 15% !important; justify-content: center !important;}
	.payments.sales .td:nth-child(3) { width: 15% !important;}
	.payments.sales .td:nth-child(4) { width: 15% !important;}
	.payments.sales .td:nth-child(5) { width: 15% !important;}
	.payments.sales .td:nth-child(6) { width: 15% !important;}
	.payments.sales .td:nth-child(7) { width: 15% !important;}
	
	.payments.sales .p-header {display: block !important; text-align: center;}
	.payments.sales .p-header .page-title { display: inline-block; position: absolute; left: 25px; top: 20px;}
	.payments.sales .cont-toggle {display: inline-block; margin-right: 20px;}
	.payments.sales .cont-toggle .btn-toggle {
		display: inline-flex; 
		justify-content: center; 
		align-items: center; 
		width: 80px; 
		padding: 8px 0; 
		border: 1px solid #C1C8D8; 
		background-color: #ffffff; 
		margin-right: -1px; 
		cursor: pointer;
	} 
	.payments.sales .cont-toggle .btn-toggle.active {border: 2px solid #00BFA5; color: #00bfa5; font-weight: bold;}
	.payments.sales .cont-toggle .btn-toggle:last-child {}
	.payments.sales .cont-date {display: inline-block !important;}
	.payments.sales .cont-date span {margin: 0 12px;}
	.payments.sales .cont-date .parent-datepicker {display: inline-block !important; background-color: #fff; border: 1px solid #C1C8D8; padding: 0 10px; cursor: pointer;}
	.payments.sales .cont-date .parent-datepicker input {margin-right: 10px; border: none; background: transparent; padding: 8px 0; border-right: 1px solid #C1C8D8; color: black;}
	
	.payments.sales .btn-excel {float: right;}
</style>





















